import {
  Box,
  Breadcrumbs,
  ButtonProps,
  Grid,
  IconButton,
  Typography,
  Link,
  Button,
  CardContent,
  Card,
  TextField,
  Divider,
  Stack,
  Tab,
} from "@mui/material";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  IQuestionType,
  IQuestionTypeViewData,
  IUploadFile,
} from "../../Services/QuestionTypesService";
import loginService from "../../../Login/Services/LoginService";
import { useNavigate } from "react-router-dom";
import { checkValidation } from "../../../../utils";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import styled from "@emotion/styled";
import DialogConfirm from "../../../../components/DialogConfirm";
import langs from "../../../../constants/langs";
import { TabContext, TabList, TabPanel } from "@mui/lab";

interface QuestionTypeCreateViewProps {
  getOne: (id: string) => void;
  onCreate: (data: IQuestionType, uploadFile: IUploadFile) => void;
  onUpdate: (id: string, data: IQuestionType, uploadFile: IUploadFile) => void;
  onDelete: (id: string) => void;
  data: IQuestionTypeViewData;
  questionTypeId: string;
  previousImage: any;
}

const QuestionTypeCreateView: FC<QuestionTypeCreateViewProps> = (props) => {
  const user = loginService.user;
  const navigate = useNavigate();

  const [questionTypeName, setQuestionTypeName] = useState("");
  const [order, setOrder] = useState(1);
  const [selectedFile, setSelectedFile] = useState<any>();
  const initialTitleNames = useMemo(() =>
    Object.fromEntries(langs.map(lang => [lang.value, ""]))
    , []);
  const [value, setValue] = useState("th");

  const [titleNames, setTitleNames] = useState<{ [key: string]: string; }>(initialTitleNames);
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    title: "",
    message: "",
    color: "primary" as ButtonProps["color"],
    type: "create" as "create" | "edit" | "delete",
  });

  useEffect(() => {
    if (props.data && props.data.details) {
      const titles: { [key: string]: string; } = {};
      props.data.details.forEach(detail => {
        titles[detail.locale] = detail.name ?? "";
      });
      setTitleNames(titles);
    }
    setQuestionTypeName(props.data.name ?? "");
    setOrder(props.data.order ?? 1);

  }, [props.data]);

  const handleTitleChange = (value: string, locale: string) => {
    setTitleNames(prevState => ({
      ...prevState,
      [locale]: value,
    }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleSave = () => {
    if (checkValidate()) {
      const questionTypeDetails = langs.map(lang => ({
        locale: lang.value,
        name: titleNames[lang.value],
      }));
      props.onCreate(
        {
          name: questionTypeName,
          order: order,
          createAt: new Date(),
          createBy: user?.id ?? "",
          details: questionTypeDetails
        },
        { file: selectedFile }
      );
    }
  };

  const handleEdit = () => {
    if (checkValidate()) {
      const questionTypeDetails = langs.map(lang => ({
        locale: lang.value,
        name: titleNames[lang.value],
      }));
      props.onUpdate(
        props.questionTypeId ?? "",
        {
          name: questionTypeName,
          order: order,
          updateAt: new Date(),
          updateBy: user?.id ?? "",
          details: questionTypeDetails
        },
        selectedFile
      );
    }
  };

  const handleDelete = () => {
    props.onDelete(props.questionTypeId ?? "");
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const checkValidate = () => {
    if (!checkValidation(titleNames[value], "กรุณากรอกชื่อเรื่อง")) return false;
    if (!checkValidation(order, "กรุณากรอกลําดับ")) return false;
    if (!selectedFile && !props.previousImage) {
      if (!checkValidation(selectedFile, "กรุณาเลือกไฟล์")) return false;
    }
    return true;
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });



  return (
    <Box component={"div"}>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={0.5}>
          <IconButton
            aria-label="delete"
            onClick={() => navigate("/questions-type-list")}
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item xs={8.5} display={"flex"} alignItems={"center"}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography>คำถาม</Typography>
            <Link
              component="button"
              onClick={() => navigate("/questions-type-list")}
            >
              ประเภทคำถาม
            </Link>
            <Typography variant="h6" color="text.primary">
              {props.questionTypeId ? "แก้ไข" : "สร้าง"}
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={3} display={"flex"} justifyContent={"end"}>
          {props.questionTypeId && (
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => {
                setOpenConfirm({
                  open: true,
                  title: "ลบข้อมูล",
                  message: "คุณต้องการลบประเภทคําถามนี้ใช่หรือไม่",
                  color: "error",
                  type: "delete",
                });
              }}
            >
              ลบข้อมูล
            </Button>
          )}
        </Grid>

        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="ลำดับ"
                      value={order}
                      type="number"
                      onChange={(e) =>
                        setOrder(Math.max(parseInt(e.target.value), 0))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleChange}>
                          {langs.map(lang => (
                            <Tab key={lang.value} value={lang.value} label={lang.name} />
                          ))}
                        </TabList>
                      </Box>
                      {langs.map((lang) => (
                        <TabPanel

                          key={lang.value} value={lang.value}
                        >
                          <Grid item xs={12} mb={2}>
                            <TextField
                              id="name"
                              label="ชื่อเรื่อง"
                              variant="outlined"
                              fullWidth
                              value={titleNames[lang.value]}
                              onChange={(e) => handleTitleChange(e.target.value, lang.value)}
                            />
                          </Grid>
                        </TabPanel>
                      ))}
                    </TabContext>
                  </Grid>


                  <Grid item xs={12} mt={1}>
                    <Typography
                      variant="body1"
                      color="textSecondary"
                      fontWeight={"bold"}
                      fontSize={16}
                    >
                      รูปไอคอน
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<UploadFileIcon />}
                    >
                      เลือกไฟล์
                      <VisuallyHiddenInput
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    {selectedFile && (
                      <Box>
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected"
                          style={{
                            maxWidth: 300,
                            maxHeight: "auto",
                          }}
                        />
                      </Box>
                    )}
                    {props.data.image && !selectedFile ? (
                      <Box>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/uploads/${(props.data.image as any).name
                            }`}
                          alt="Selected"
                          style={{
                            maxWidth: 300,
                            maxHeight: "auto",
                          }}
                        />
                      </Box>
                    ) : (
                      []
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={8}></Grid>
                  <Grid item xs={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack spacing={2} direction="row">
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => navigate("/questions-type-list")}
                          >
                            ยกเลิก
                          </Button>
                          {
                            <Button
                              variant="contained"
                              fullWidth
                              onClick={() => {
                                if (props.questionTypeId) {
                                  setOpenConfirm({
                                    open: true,
                                    title: "แก้ไขประเภทคำถาม",
                                    message:
                                      "คุณต้องการแก้ไขประเภทคำถามนี้ใช่หรือไม่",
                                    color: "primary",
                                    type: "edit",
                                  });
                                } else {
                                  setOpenConfirm({
                                    open: true,
                                    title: "บันทึกประเภทคำถาม",
                                    message:
                                      "คุณต้องการบันทึกประเภทคำถามนี้ใช่หรือไม่",
                                    color: "primary",
                                    type: "create",
                                  });
                                }
                              }}
                            >
                              บันทึก
                            </Button>
                          }
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <DialogConfirm
        open={openConfirm.open}
        type={openConfirm.color}
        title={openConfirm.title}
        message={openConfirm.message}
        onClose={() => setOpenConfirm({ ...openConfirm, open: false })}
        onSubmit={() => {
          setOpenConfirm({ ...openConfirm, open: false });
          if (openConfirm.type === "delete") {
            handleDelete();
          } else if (openConfirm.type === "create") {
            handleSave();
          } else if (openConfirm.type === "edit") {
            handleEdit();
          }
        }}
      />
    </Box>
  );
};

export default QuestionTypeCreateView;
