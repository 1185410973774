import React from 'react';
import './index.css';
import App from './App';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./themes";
import { SnackbarProvider } from "notistack";
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider maxSnack={5} anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }} autoHideDuration={3000}>
                <App />
            </SnackbarProvider>
        </ThemeProvider>
    </React.StrictMode>
);