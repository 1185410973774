import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  IFormula,
  IFormulaCriteria,
  IFormulaView,
} from "../Services/FormulasService";
import { IGod } from "../../Gods/Services/GodsService";
import { FC, useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { NoteAdd as NoteAddIcon } from "@mui/icons-material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DialogCreateFormula from "../../../components/DialogCreateFormula";
import { IQuestionsGroup } from "../../Questions/Services/QuestionsService";
interface FormulasListControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
  formulas: IFormulaView;
  onSearch: (criteria: IFormulaCriteria) => void;
  groups: IQuestionsGroup[];
  gods: IGod[];
  getOne: (id: string) => void;
  data?: IFormula;
  onDelete: (id: string) => void;
  onSubmit: (data: IFormula) => void;
}
const FormulasListView: FC<FormulasListControllerProps> = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [group, setGroup] = useState(0);
  const [god, setGod] = useState(0);
  const [open, setOpen] = useState(false);
  const [formulaDetail, setFormulaDetail] = useState<any>({
    open: false,
    data: [],
    gods: props.gods,
    groups: props.groups,
  });

  window.addEventListener("beforeunload", function (event) {
    localStorage.removeItem("group");
    localStorage.removeItem("god");
    localStorage.removeItem("page");
    localStorage.removeItem("limit");
  });

  const searchByCriteria = (pageNumber: number = 0, rowLimit?: number) => {
    setPage(pageNumber);
    setLimit(rowLimit ? rowLimit : 10);
    const _god: number = parseInt(localStorage.getItem("god") || "0");
    const _group: number = parseInt(localStorage.getItem("group") || "0");
    const criteria: IFormulaCriteria = {
      god: _god,
      questionGroup: _group,
      page: pageNumber,
      limit: rowLimit ?? 10,
    };
    props.onSearch(criteria);
  };

  const handleOpenDetail = (id?: string) => {
    setOpen(true);
    if (id) {
      props.getOne(id);
    } else {
      setFormulaDetail({
        open: true,
        data: null,
        gods: props.gods,
        groups: props.groups,
      });
    }
  };

  const handleCloseDetail = () => {
    setFormulaDetail({
      open: false,
      data: null,
      gods: props.gods,
      groups: props.groups,
    });
    setOpen(false);
  };

  useEffect(() => {
    const _god: number = parseInt(localStorage.getItem("god") || "0");
    const _group: number = parseInt(localStorage.getItem("group") || "0");
    setGod(_god);
    setGroup(_group);
    setFormulaDetail({
      open: open,
      data: props.data,
      gods: props.gods,
      groups: props.groups,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  return (
    <Box component={"div"}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        <Typography>สูตรคำนวณ</Typography>
        <Typography variant="h6" color="text.primary">
          รายการ
        </Typography>
      </Breadcrumbs>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel id="promotionType-select-label">
                      กลุ่มคําถาม
                    </InputLabel>
                    <Select
                      labelId="promotionType-select-label"
                      value={group}
                      id="promotionType-select"
                      label="กลุ่มคําถาม"
                      onChange={(e) => {
                        setGroup(e.target.value as number);
                        localStorage.setItem(
                          "group",
                          JSON.stringify(e.target.value)
                        );
                      }}
                    >
                      <MenuItem value={0}>ทั้งหมด</MenuItem>
                      {props.groups && props.groups.length > 0 ? (
                        props.groups.map((group) => (
                          <MenuItem key={group.id} value={group.id}>
                            {group.name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth>
                    <InputLabel id="promotionType-select-label">
                      เทพเจ้า
                    </InputLabel>
                    <Select
                      labelId="promotionType-select-label"
                      value={god}
                      id="promotionType-select"
                      label="เทพเจ้า"
                      onChange={(e) => {
                        setGod(e.target.value as number);
                        localStorage.setItem(
                          "god",
                          JSON.stringify(e.target.value)
                        );
                      }}
                    >
                      <MenuItem value={0}>ทั้งหมด</MenuItem>
                      {props.gods && props.gods.length > 0 ? (
                        props.gods.map((god) => (
                          <MenuItem key={god.id} value={god.id}>
                            {god.title}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem>ไม่พบข้อมูล</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} textAlign={"center"} alignSelf={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => searchByCriteria(0, limit)}
                    startIcon={<SearchIcon />}
                  >
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography>
                        ทั้งหมด {props.formulas.total} รายการ
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<NoteAddIcon />}
                        onClick={() => handleOpenDetail()}
                      >
                        สร้าง
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            rowSpan={2}
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            เทพเจ้า
                          </TableCell>
                          <TableCell
                            rowSpan={2}
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            กลุ่มคําถาม
                          </TableCell>
                          <TableCell
                            colSpan={2}
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            baziYear
                          </TableCell>
                          <TableCell
                            colSpan={2}
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            baziMonth
                          </TableCell>
                          <TableCell
                            colSpan={2}
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            baziDay
                          </TableCell>
                          <TableCell
                            colSpan={2}
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            baziHour
                          </TableCell>
                          <TableCell
                            colSpan={2}
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            mingGua
                          </TableCell>
                          <TableCell rowSpan={2}></TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            Tg
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            Dz
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            Tg
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            Dz
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            Tg
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            Dz
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            Tg
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            Dz
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            M
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ borderRight: "1px solid #dee2e6" }}
                          >
                            F
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.formulas.datas && props.formulas.datas.length > 0
                          ? props.formulas.datas.map((formula) => (
                              <TableRow key={`formula-${formula.id}`}>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.god?.godDetails?.[0]?.title || ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.questionGroup?.name || ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.baziYearTg ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.baziYearDz ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.baziMonthTg ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.baziMonthDz ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.baziDayTg ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.baziDayDz ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.baziHourTg ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.baziHourDz ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.mingGuaM ?? ""}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ borderRight: "1px solid #dee2e6" }}
                                >
                                  {formula?.mingGuaF ?? ""}
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    aria-label="detail"
                                    onClick={() => {
                                      handleOpenDetail(formula?.id);
                                    }}
                                  >
                                    <HelpOutlineIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))
                          : []}
                        {props.formulas.datas &&
                          props.formulas.datas.length === 0 && (
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={13}
                                sx={{ borderRight: "1px solid #dee2e6" }}
                              >
                                ไม่พบข้อมูล
                              </TableCell>
                            </TableRow>
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={3}>
                    <TablePagination
                      component="div"
                      rowsPerPageOptions={[
                        10,
                        25,
                        50,
                        100,
                        { label: "All", value: -1 },
                      ]}
                      count={props.formulas.total ?? 0}
                      onPageChange={(e, newPage) => {
                        setPage(newPage);
                        searchByCriteria(newPage);
                      }}
                      page={page}
                      rowsPerPage={limit ? limit : 10}
                      onRowsPerPageChange={(e: any) => {
                        setLimit(e.target.value);
                        setPage(0);
                        searchByCriteria(page, e.target.value);
                        localStorage.setItem(
                          "limit",
                          JSON.stringify(e.target.value)
                        );
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogCreateFormula
        open={formulaDetail.open}
        data={formulaDetail.data}
        gods={formulaDetail.gods}
        groups={formulaDetail.groups}
        onClose={handleCloseDetail}
        onSubmit={(data) => {
          props.onSubmit(data);
          setPage(0);
          handleCloseDetail();
        }}
        onDelete={(id) => {
          props.onDelete(id);
          setPage(0);
          handleCloseDetail();
        }}
      />
    </Box>
  );
};

export default FormulasListView;
