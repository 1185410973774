import { FC, useEffect, useState } from "react";
import GodCreateView from "../Views/GodCreateView";
import GodsService, { IGod, IUploadFile } from "../Services/GodsService";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { deleteFileImage, deletePreviousImage } from "../../../utils";

interface GodCreateControllerProps {
  // รับ props ต่างๆที่จำเป็นสำหรับการจัดการข้อมูล
}
const GodCreateController: FC<GodCreateControllerProps> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState<IGod>();

  const [previousGodImage, setPreviousGodImage] = useState<any>();

  const [previousWallpapers, setPreviousWallpapers] = useState<any>();
  const [deleteWallpapers, setDeleteWallpapers] = useState<any>();

  useEffect(() => {
    if (id) {
      getOne(Number(id));
    }
  }, [id]);

  const create = async (data: IGod, uploadFile: IUploadFile) => {
    await GodsService.create(data, uploadFile)
      .then(async (res: any) => {
        if (res) {
          const godId = res;
          if (
            uploadFile.wallpaperFileMulti &&
            uploadFile.wallpaperFileMulti.length > 0
          ) {
            await uploadFile.wallpaperFileMulti.forEach((file: any) => {
              GodsService.uploadWallpapers(godId, { wallpaperFileMulti: file });
            });

            navigate(`/god-edit/${godId}`);
            enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
            window.location.reload();
          }

        } else {
          enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const update = async (
    id: number,
    data: IGod,
    godImage: IUploadFile,
    godWallpapers: IUploadFile[]
  ) => {
    const newGodImage = godImage && godImage !== previousGodImage;
    const newWallpapers = godWallpapers?.filter(
      (item) => !previousWallpapers.includes(item)
    );
    try {
      const updatedRes = await GodsService.update(id, data);
      if (updatedRes) {
        if (!previousGodImage) {
          await GodsService.uploadGods(id.toString(), { godFile: godImage });
        } else if (newGodImage) {
          const deleteRespone = await deletePreviousImage(previousGodImage.id);
          await deleteFileImage(previousGodImage.name);
          if (deleteRespone) {
            const data = { isDelete: true };
            await GodsService.updateGodImage(previousGodImage.id, data);
            await GodsService.uploadGods(id.toString(), { godFile: godImage });
          }
        }
        if (newWallpapers && newWallpapers.length > 0) {
          const uploadPromises = newWallpapers.map((file) => {
            return GodsService.uploadWallpapers(id, {
              wallpaperFileMulti: file,
            });
          });

          await Promise.all(uploadPromises);
        }
        if (deleteWallpapers && deleteWallpapers.length > 0) {
          const deletePromises = deleteWallpapers.map((file: any) => {
            return GodsService.deleteWallpapers(id, {
              wallpaperFileMulti: file,
            });
          });
          await Promise.all(deletePromises);
        }

        await getOne(id);
        window.location.reload();
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      } else {
        enqueueSnackbar("บันทึกข้อมูลไม่สำเร็จ", { variant: "error" });
      }
    } catch (error) {
      console.error("เกิดข้อผิดพลาดในการอัพเดต: ", error);
      enqueueSnackbar("เกิดข้อผิดพลาดในการอัพเดต", { variant: "error" });
    }
  };

  const getOne = async (id: number) => {
    const res = await GodsService.getOne(id);
    if (res) {
      setData(res);
      if (res.image) {
        setPreviousGodImage(res.image);
      } else {
        setPreviousGodImage(null);
      }
      if (res.wallpaper && res.wallpaper.length > 0) {
        setPreviousWallpapers(res.wallpaper);
      } else {
        setPreviousWallpapers([]);
      }
    }
  };

  const deleteGod = async (id: number) => {
    await GodsService.deleteGod(id)
      .then((res) => {
        if (res) {
          enqueueSnackbar("ลบข้อมูลสำเร็จ", { variant: "success" });
          navigate("/gods-list");
        } else {
          enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("ลบข้อมูลไม่สำเร็จ", { variant: "error" });
      });
  };

  const removeWallpaperByIndex = async (index: number) => {
    if (data?.wallpaper && data.wallpaper.length > 0) {
      const deleteWallpaper = data.wallpaper[index];
      if (deleteWallpapers) {
        setDeleteWallpapers([...deleteWallpapers, deleteWallpaper]);
      } else {
        setDeleteWallpapers([deleteWallpaper]);
      }
      const newWallpapers = data.wallpaper.filter((item, i) => i !== index);
      setData({ ...data, wallpaper: newWallpapers });
    }
  };

  return (
    <GodCreateView
      getOne={getOne}
      onCreate={create}
      onUpdate={(
        id: number,
        data: IGod,
        godImage: IUploadFile,
        wallpapers: IUploadFile[]
      ) => {
        update(id, data, godImage, wallpapers);
      }}
      onDelete={deleteGod}
      data={data ?? ({} as IGod)}
      godId={id ?? ""}
      removeWallpaper={(index: number) => {
        removeWallpaperByIndex(index);
      }}
    />
  );
};

export default GodCreateController;
