import { makeAutoObservable } from "mobx";
import apiService from "../../../services/ApiService";
import { IUser } from "../../Settings/Services/SystemUsersService";

export interface IGod {
  id?: number;
  title?: string;
  description?: string;
  imageGods?: string;
  imageWallpapers?: string;
  image?: ShowImages;
  wallpaper?: ShowImages[];
  titlePremium?: string;
  descriptPremium?: string;
  isFavorite?: boolean;
  isDelete?: boolean;
  createAt?: Date;
  updateAt?: Date;
  createBy?: string;
  updateBy?: string;
  godDetails?: IGodDetail[];
}

export interface IGodDetail {
  locale: string;
  title: string;
  description: string;
  titlePremium: string;
  descriptPremium: string;
}

export interface IGodUpdateFavoriteChecked {
  isFavorite: boolean;
  updateAt: Date;
}

export interface ShowImages {
  id?: string;
  name?: string;
  refId?: string;
  refType?: string;
  isDelete?: boolean;
  createAt?: Date;
  updateAt?: Date;
}

export interface IGodViewData {
  id?: number;
  title?: string;
  description?: string;
  imageGods?: string;
  imageWallpapers?: string;
  image?: ShowImages;
  wallpaper?: ShowImages[];
  titlePremium?: string;
  descriptPremium?: string;
  isFavorite?: boolean;
  isDelete?: boolean;
  createAt: Date;
  updateAt: Date;
  createBy?: IUser;
  updateBy?: IUser;
}

export interface IUploadFile {
  godFile: any;
  wallpaperFileMulti: any;
}

export interface IGodCriteria {
  title: string;
  isFavorite: boolean;
  sortBy?: ISortBy;
  page: number;
  limit: number;
}

export interface ISortBy {
  name: string;
  value: string;
  sortBy: string;
  sortType: "ASC" | "DESC";
}

export interface IGodView {
  datas: IGodViewData[];
  all: number;
  limit: number;
  page: number;
  total: number;
}

class GodsService {
  gods: IGodView = {} as IGodView;

  construnctor() {
    makeAutoObservable(this);
  }

  async getAll() {
    try {
      const response = await apiService.get("/gods");
      if (response.length > 0) {
        this.gods = {
          datas: response.filter((x: IGod) => !x.isDelete),
          all: response.length,
          limit: 10,
          page: 1,
          total: response.length
        };
      }
      return this.gods;
    } catch (error) {
      throw error;
    }
  }

  async getOne(id: number) {
    try {
      const response = await apiService.get(`/gods/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async getByCriteria(criteria: IGodCriteria) {
    try {
      const response = await apiService.post("/gods/criteria", criteria);
      this.gods = {
        datas: response.data.filter((x: IGod) => !x.isDelete),
        all: response.length,
        limit: response.limit,
        page: response.page,
        total: response.total
      };
      return this.gods;
    } catch (error) {
      throw error;
    }
  }

  async create(data: IGod, uploadFile: IUploadFile) {
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    try {
      let response = '';
      await apiService.post("/gods", data).then(async (result) => {
        const formData = new FormData();
        formData.append('file', uploadFile.godFile);
        formData.append('refId', result.id);
        formData.append('refType', 'god');
        //TODO - เพิ่มการสร้าง user
        formData.append('createBy', user.id || 'admin');
        formData.append('createAt', new Date(Date.now()).toISOString());
        await apiService.postFormData("/images/upload", formData).then((res) => {
          if (res) {
            response = result.id;
          }
        });
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async uploadGods(id: string, uploadFile: any) {
    const user = JSON.parse(localStorage.getItem("user") || '{}');
    try {
      let response = '';
      const formData = new FormData();
      formData.append('file', uploadFile.godFile);
      formData.append('refId', id);
      formData.append('refType', 'god');
      //TODO - เพิ่มการสร้าง user
      formData.append('createBy', user.id || 'admin');
      formData.append('createAt', new Date(Date.now()).toISOString());
      await apiService.postFormData("/images/upload", formData).then((res) => {
        if (res) {
          response = id;
        }
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  async uploadWallpapers(id: number, uploadFile: any) {
    try {
      const user = JSON.parse(localStorage.getItem("user") || '{}');
      const formData = new FormData();
      formData.append('file', uploadFile.wallpaperFileMulti);
      formData.append('refId', id.toString());
      formData.append('refType', 'wallpaper');
      formData.append('createBy', user.id || 'admin');
      formData.append('createAt', new Date().toISOString());
      const response = await apiService.postFormData("/wallpaper/upload/god", formData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteWallpapers(id: number, uploadFile: any) {
    try {
      const wallpaperId = uploadFile.wallpaperFileMulti.id;
      const data = { isDelete: true };
      let response = '';
      await apiService.put(`/wallpaper/${wallpaperId}`, data).then(async (res) => {
        if (res) {
          const data = { filename: uploadFile.wallpaperFileMulti.name };
          await apiService.post('/wallpaper/delete/wallpaper', data).then((res) => {
            if (res) {
              response = res;
            }
          });
        }
        return response;
      });
    } catch (error) {
      throw error;
    }
  }

  async updateGodImage(id: number, data: ShowImages) {
    try {
      const response = await apiService.put(`/images/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async update(id: number, data: IGod) {
    try {
      const response = await apiService.put(`/gods/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateFavoriteChecked(id: number, data: IGodUpdateFavoriteChecked) {
    try {
      const response = await apiService.put(`/gods/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async deleteGod(id: number) {
    try {
      const data = { isDelete: true };
      const response = await apiService.put(`/gods/${id}`, data);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

const godsService = new GodsService();
export default godsService;
